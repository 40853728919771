<template>
  <div class="fundOrgTable">
    <PocTable
      :loading="queryLoading"
      :data="tableData"
      stripe
      :current-page.sync="pageConfig.pageNum"
      :total="pageConfig.total"
      @size-change="pageSizeChange"
      @page-current-change="pageChange"
      @row-click="goDetail"
      :max-height="maxHeight"
      ref="multipleTable"
    >
      <el-table-column
        v-for="item in columnConfig"
        :key="item.key"
        :prop="item.key"
        :label="item.name"
        :fixed="item.fixed"
        :sortable="item.sortable"
        :width="item.width"
        align="left"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span
            v-if="item.key === 'orgName'"
            class="orgName"
            @click="goDetail(scope.row)"
            >{{ scope.row[item.key] }}</span
          >
          <span v-else>{{ scope.row[item.key] }}</span>
        </template>
      </el-table-column>
    </PocTable>
    <scopeTransfer
      ref="scopeTransferRef"
      @onConfirm="onConfirm"
      fromPage="fundsPage"
      dialog-title="分配组织机构"
    />
  </div>
</template>
<script>
import scopeTransfer from '@/components/ScopeTransfer/ScopeTransfer'
export default {
  name: 'fundsFormTable',
  mixins: [$PCommon.TableMixin],
  components: {
    scopeTransfer
  },
  props: {
    editFlag: {
      type: String
    },
    addFlag: {
      type: Object
    }
  },
  data() {
    const h = document.getElementsByTagName('body')[0].clientHeight - 420 + 'px'
    return {
      maxHeight: h,
      queryLoading: false,
      tableData: [],
      columnConfig: [
        {
          key: 'solutionHub',
          name: 'Solution Hub',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'hfmCode',
          name: 'HFM Code',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'internalBrandName',
          name: 'Internal Brand Name',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 140 // 默认宽度，像素
        },
        {
          key: 'city',
          name: 'City',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'businessUnitId',
          name: 'Business Unit ID',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        },
        {
          key: 'businessUnitName',
          name: 'Business Unit Name',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: false // 默认宽度，像素
        }
      ]
    }
  },
  watch: {},
  created() {
    this.pageConfig.pageNum = 1
    if (this.addFlag.addFlag !== 'add') {
      this.getOrgList()
    }
  },
  computed: {},
  methods: {
    async getOrgList() {
      // 获取分配组织明细列表
      const { data, code } = await this.$Api.funds.getOrgList({
        id: this.addFlag.fundsId,
        ...this.pageConfig
      })
      this.queryLoading = false
      if (code === 200) {
        this.tableData = data
        console.log('0000000000000', this.tableData)
        this.pageConfig.total = data.length
        // this.$forceUpdate()
      }
    },
    goDetail(row) {
      this.$refs.scopeTransferRef.show({
        id: row.id || '',
        positions: 'fundsList',
        treeTitle: ['待分配组织', '已分配机构'],
        placeholder: '请输入组织名称'
      })
    },
    onConfirm(val) {
      if (val) {
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {})
    window.removeEventListener('keyup', () => {})
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if ((e.code == 'Enter' || e.target == 13) && e.target.id == 'inputid') {
        console.log(e)
        this.filterQuery()
      }
    })
    window.addEventListener('resize', () => {
      const h =
        document.getElementsByTagName('body')[0].clientHeight - 340 + 'px'
      this.maxHeight = h
    })
  }
}
</script>
<style lang="scss" scoped>
.orgName:hover {
  cursor: pointer;
  text-decoration: underline;
}
.budget-form-table-page {
  margin-bottom: $MP32;

  .el-table .cell {
    padding: 0;
    line-height: $MP24;
  }
  .require-color {
    color: $--color-require-text;
  }
  .err {
    ::v-deep .el-input__inner {
      border-color: $--color-error;
    }
  }
  .require-item::before {
    content: '*';
    color: $--color-require-text;
    margin-right: $MP4;
  }

  ::v-deep .el-table__body-wrapper {
    padding-bottom: $MP16;
  }
  ::v-deep .el-table .cell {
    padding: 0 $MP2;
  }
}
</style>
<style lang="scss">
.fundOrgTable {
}
</style>
