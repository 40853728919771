<template>
  <div>
    <div v-show="!addFundsFlag">
      <div class="funds-add-page">
        <PocCardTable class="irisePocCardTable filterContainer topPocCardTable">
          <template v-slot:headerTitle
            ><span v-if="addFlag.addFlag == 'goDetail'" style="font-weight: 400"
              >社保/公积金管理详情</span
            >
            <span v-else style="font-weight: 400"
              >社保/公积金管理</span
            ></template
          >
          <template v-slot:headerGroup style="display: flex">
            <el-button :loading="queryLoading" @click="cancle">返回</el-button>
            <el-upload
              style="display: inline-block"
              action=""
              ref="upload"
              :on-preview="handlePreview"
              :on-change="onChangeFile"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              :loading="queryLoading"
              multiple
              :limit="1"
              :on-exceed="handleExceed"
              :file-list="fileList"
              :auto-upload="false"
              role="export"
              v-permission
            >
              <el-button> 导入</el-button>
            </el-upload>
            <el-button
              v-if="addFlag.addFlag === 'goDetail'"
              type="primary"
              class="buttonActive"
              @click="goEdit"
              role="edit"
              v-permission
              >编辑</el-button
            >
            <el-button
              v-else
              type="primary"
              class="buttonActive"
              @click="submitForm"
              role="socialsave"
              v-permission
              >保存</el-button
            >
          </template>
          <template>
            <!-- 详情的区域 -->
            <div v-if="addFlag.addFlag === 'goDetail'">
              <div class="flex-1 flex AlCenter">
                <div class="flex-1 flexBetw">
                  <div class="flex-1">
                    <span class="lableDetail">名称</span>
                    <span class="lableContent">{{
                      selectEditMsg.socialSecurityBaseTopicVo.name
                    }}</span>
                  </div>
                  <div class="flex-1">
                    <span class="lableDetail">生效日期</span>
                    <span class="lableContent">{{
                      selectEditMsg.socialSecurityBaseTopicVo.effectiveDate
                    }}</span>
                  </div>
                  <div class="flex-1">
                    <span class="lableDetail">失效日期</span>
                    <span class="lableContent">{{
                      selectEditMsg.socialSecurityBaseTopicVo.invalidDate
                    }}</span>
                  </div>
                  <div class="flex-1">
                    <span class="lableDetail">状态</span>
                    <span class="lableContent">{{
                      Number(selectEditMsg.socialSecurityBaseTopicVo.status)
                        ? '停用'
                        : '启用'
                    }}</span>
                  </div>
                </div>
                <div class="upWord" style="width: 82px"></div>
              </div>
              <div
                class="flex-1 flex AlCenter"
                style="margin-top: 26px; margin-bottom: 12px"
              >
                <div class="flex-1 flexBetw">
                  <div class="flex-2">
                    <span class="lableDetail">备注</span>
                    <span class="lableContent">{{
                      selectEditMsg.socialSecurityBaseTopicVo.remark
                    }}</span>
                  </div>
                </div>
                <div class="upWord" style="background: red; width: 82px"></div>
              </div>
            </div>
            <!-- 新增，编辑，复制等区域 -->
            <el-form :inline="true" v-else class="filter-form rpm-query-from">
              <el-form-item label="名称" label-position="top">
                <el-input
                  placeholder="请输入名称"
                  v-model="selectEditMsg.socialSecurityBaseTopicVo.name"
                >
                </el-input>
              </el-form-item>
              <el-form-item label="生效日期" label-position="top">
                <el-date-picker
                  v-model="
                    selectEditMsg.socialSecurityBaseTopicVo.effectiveDate
                  "
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>

              <el-form-item label="失效日期" label-position="top">
                <el-date-picker
                  v-model="selectEditMsg.socialSecurityBaseTopicVo.invalidDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="状态">
                <el-select
                  clearable
                  v-model="selectEditMsg.socialSecurityBaseTopicVo.status"
                  filterable
                  placeholder="请选择状态"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in statusList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="upFlag" label="备注">
                <el-input
                  placeholder="请输入备注"
                  v-model="selectEditMsg.socialSecurityBaseTopicVo.remark"
                  maxlength="50"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-form>
          </template>
        </PocCardTable>
        <PocCardTable class="irisePocCardTable topTabFlex">
          <div class="flex">
            <div class="shebao">
              <!--              <el-tabs v-model="chooseTab">-->
              <!--                <el-tab-pane-->
              <!--                  label="社保公积金"-->
              <!--                  name="providentFund"-->
              <!--                ></el-tab-pane>-->
              <!--                &lt;!&ndash;                <el-tab-pane&ndash;&gt;-->
              <!--                &lt;!&ndash;                  label="已分配机构"&ndash;&gt;-->
              <!--                &lt;!&ndash;                  name="assignedAgency"&ndash;&gt;-->
              <!--                &lt;!&ndash;                ></el-tab-pane>&ndash;&gt;-->
              <!--              </el-tabs>-->
              社保公积金
            </div>
            <div class="rightButton">
              <el-button
                type="primary"
                @click="distributionOrg"
                :disabled="addFlag.addFlag === 'goDetail'"
                role="socialcopy"
                v-permission
                >添加</el-button
              >
            </div>
          </div>
          <template>
            <fundsFormTable
              v-if="chooseTab === 'providentFund'"
              :importData="importData"
              :addFlag="addFlag"
              @orgSave="orgSave"
              ref="formTableRef"
            />
          </template>
          <template>
            <fundsOrgTable
              v-if="chooseTab === 'assignedAgency'"
              :chooseTab="chooseTab"
              :addFlag="addFlag"
              ref="formTableRef2"
            />
          </template>
        </PocCardTable>
        <scopeTransfer
          ref="scopeTransferRef"
          @onConfirm="onConfirm"
          fromPage="fundList"
          dialog-title="分配组织机构"
        />
        <OrganizationsDialog
          ref="OrganizationsDialogRef"
          @onConfirm="onConfirm"
        />
      </div>
    </div>
  </div>
</template>
<script>
import scopeTransfer from '@/components/ScopeTransfer/ScopeTransfer'
import fundsOrgTable from './fundsOrgTable'
import fundsFormTable from './fundsFormTable'
import DeleteConfirmDialog from '@/components/DeleteConfirmDialog'
import OrganizationsDialog from '@/views/fundsManage/dialog/OrganizationsDialog'
import { getTheyearOptions } from '@/utils/util'
import { mapGetters } from 'vuex'
const { PocResetMessage } = $PCommon
export default {
  name: 'BudgetAdd',
  components: {
    fundsOrgTable,
    fundsFormTable,
    DeleteConfirmDialog,
    scopeTransfer,
    OrganizationsDialog
  },
  props: {
    addFlag: {
      type: Object
    }
  },
  data() {
    return {
      fileList: [],
      againTableList: [],
      buttonDisable: false, // 按钮默认可点
      orgFlag: false, // 分配按钮默认为可以点击
      selectEditMsg: {
        list: [],
        orgList: [],
        socialSecurityBaseTopicVo: {
          name: '',
          status: '',
          effectiveDate: '',
          invalidDate: '',
          remark: ''
        }
      },
      selectStatus: '',
      queryLoading: false,
      importData: [],
      upFlag: true,
      statusList: [
        { id: '0', name: '启用' },
        { id: '1', name: '停用' }
      ],
      addFundsFlag: '',
      chooseTab: 'providentFund'
    }
  },
  created() {
    this.addFlag.fundsMsg.status =
      this.addFlag.fundsMsg.status === '启用' ? '0' : '1'
    this.selectEditMsg.organizationsList =
      this.addFlag.fundsMsg.organizationsList || []
    if (
      this.addFlag.addFlag === 'copy' ||
      this.addFlag.addFlag === 'goDetail' ||
      this.addFlag.addFlag === 'edit'
    ) {
      this.selectEditMsg.socialSecurityBaseTopicVo = this.addFlag.fundsMsg
    } else {
      // 新增进来没有id,分配组织机构按钮不可点
      this.orgFlag = true
    }
  },
  computed: {},
  methods: {
    orgSave() {
      this.submitForm('orgSave')
    },
    goEdit(row) {
      this.addFlag.addFlag = 'edit'
    },
    submitForm(val) {
      this.buttonDisable = true
      const ischeck = this.$refs.formTableRef.checkData() //验证table数据是否都填上了值
      console.log('check', ischeck)
      if (ischeck) {
        if (!this.selectEditMsg.socialSecurityBaseTopicVo.name) {
          this.$message({
            type: 'error',
            message: '名称为空!'
          })
          this.buttonDisable = false
          return
        }

        if (!this.selectEditMsg.socialSecurityBaseTopicVo.effectiveDate) {
          this.$message({
            type: 'error',
            message: '请选择生效日期!'
          })
          this.buttonDisable = false
          return
        }
        if (!this.selectEditMsg.socialSecurityBaseTopicVo.invalidDate) {
          this.$message({
            type: 'error',
            message: '请选择失效日期!'
          })
          this.buttonDisable = false
          return
        }
        if (!this.selectEditMsg.socialSecurityBaseTopicVo.status) {
          this.$message({
            type: 'error',
            message: '请选择状态!'
          })
          this.buttonDisable = false
          return
        }
        let { tableData, columnConfig } = this.$refs.formTableRef.getData()
        let field = [] //所有要转类型的key值
        let percent = [] //百分比
        columnConfig.map((v) => {
          if (v.children) {
            v.children.map((child) => {
              if (!child.isRate) {
                field.push(child.key)
              } else {
                percent.push(child.key)
              }
            })
          }
        })
        // tableData.map((v) => {
        //   Object.keys(v).map((k) => {
        //     if (field.includes(k)) {
        //       v[k] = this.money_string2num(v[k])
        //     } else if (percent.includes(k)) {
        //       v[k] = this.percent2num(v[k])
        //     }
        //   })
        // })
        if (this.addFlag.addFlag === 'add') {
        } else {
          tableData.map((d) => {
            delete d.id
          })
          console.log('========删除tipicId', tableData)

          //复制时，原有数据如果不删除，需要重新**进来
          delete this.selectEditMsg.socialSecurityBaseTopicVo.id
          delete this.selectEditMsg.socialSecurityBaseTopicVo.creatInfo
          delete this.selectEditMsg.socialSecurityBaseTopicVo.createTime
          delete this.selectEditMsg.socialSecurityBaseTopicVo.userName
          delete this.selectEditMsg.socialSecurityBaseTopicVo.createInfo
        }

        this.selectEditMsg.list = tableData
        if (
          this.addFlag.addFlag === 'edit' ||
          this.addFlag.addFlag === 'goDetail' ||
          this.addFlag.addFlag === 'saveAssign'
        ) {
          //处理编辑的时候把id处理进去，复制、导入都是新增逻辑
          this.selectEditMsg.socialSecurityBaseTopicVo.id = this.addFlag.fundsId
        }
        this.selectStatus = ''

        this.addSubmit(val)
      } else {
        PocResetMessage.error('请填写必填项')
      }
    },
    percent2num(str) {
      return str
        ? Number(Number(str.toString().replace('%', '') / 100).toFixed(4))
        : str
    },
    money_string2num(str) {
      return str ? Number(str.toString().replace(/,/g, '')) : str
    },
    upRow() {
      this.upFlag = !this.upFlag
    },
    refresh() {
      this.selectEditMsg.socialSecurityBaseTopicVo = {
        name: '',
        status: '',
        effectiveDate: '',
        invalidDate: '',
        remark: ''
      }
      this.$refs.formTableRef.getDetailList()
    },
    cancle() {
      this.$emit('goback')
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePreview(file) {
      console.log('上传文件为===', file)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    onChangeFile(file, fileList) {
      // console.log('========================================')
      // console.log(file)
      if (file.name.endsWith('.xls') || file.name.endsWith('.xlsx')) {
        this.upload(file)
      } else {
        this.$message.warning(this.$t('dc:上传文件类型错误，仅支持excel'))
      }
      this.fileList = []
    },
    // 分配组织==调出穿梭框
    distributionOrg() {
      this.$refs.formTableRef.addRow()
    },
    onConfirm(val) {
      if (val) {
        console.log('000000000000值3', val)
        // console.log('000000000000值3',val)
        // 复制里点分配=走新增。编辑。详情走编辑。新增导入返回id，然后分配可点击。走编辑
        //  this.jumpFlag = 'saveAssign'PS新增处理此逻辑
        this.selectEditMsg.socialSecurityBaseTopicVo.id = this.addFlag.fundsId
        //调取保存等一系列逻辑接口。
        this.selectEditMsg.orgList = val.split(',').map((v) => Number(v))

        this.selectEditMsg.organizationsList = val
          .split(',')
          .map((v) => Number(v))

        // console.log('000000000000值3',this.selectEditMsg.socialSecurityBaseTopicVo.orgList)
      } else {
        this.selectEditMsg.socialSecurityBaseTopicVo.id = this.addFlag.fundsId
        //调取保存等一系列逻辑接口。
        this.selectStatus = 'empty'
        this.selectEditMsg.orgList = []
        this.selectEditMsg.organizationsList = []
      }
      this.submitForm()
      this.$emit('goback')
    },
    async upload(file) {
      const formData = new FormData()
      formData.append('file', file.raw)
      // console.log('-------', file.raw, formData.getAll('file'))
      let loadingInstance = this.$loading({
        lock: true,
        text: '上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      const { code, data, msg } = await this.$Api.funds.uploadPo(formData)
      loadingInstance.close()
      if (code == 200) {
        this.$message({
          type: 'success',
          message: msg
        })
      } else {
        this.$message({
          type: 'info',
          message: msg
        })
      }
      this.fileList = []
      //渲染所有值。。。
      if (
        this.addFlag.addFlag === 'edit' ||
        this.addFlag.addFlag === 'goDetail'
      ) {
        // 如果编辑页面的导入，需要置为topic ID
        data.list.map((v) => {
          v['topicId'] = this.addFlag.fundsId
        })
      }
      this.importData = data.list
      delete data.socialSecurityBaseTopicVo.id
      this.selectEditMsg.socialSecurityBaseTopicVo =
        data.socialSecurityBaseTopicVo
      this.selectEditMsg.socialSecurityBaseTopicVo.status =
        data.socialSecurityBaseTopicVo.status === '启用' ? '0' : '1'
    },
    async addSubmit(val) {
      // console.log('提交保存-----', this.selectEditMsg)

      let funData = await this.$Api.funds.addFunds(this.selectEditMsg)
      if (funData) {
        const { code, data } = funData
        if (code === 200) {
          let message = '新增成功'
          if (this.addFlag.addFlag === 'add') {
            message = '新增成功'
          } else if (this.addFlag.addFlag === 'copy') {
            message = '复制成功'
          } else {
            message = '编辑成功'
          }
          this.$message({
            type: 'success',
            message: message
          })
          this.orgFlag = false // 此时分配组织按钮可点
          if (this.addFlag.addFlag === 'add') {
            this.addFlag.fundsId = data
            this.addFlag.addFlag = 'saveAssign' // 新增完如点组织按钮 可点.需走edit接口（PS：一样数据不能用户名一样走2次接口）
            await this.$refs.formTableRef.getDetailList()
          }
          if (this.addFlag.addFlag !== 'saveAssign') {
            if (val !== 'orgSave') {
              this.$emit('goback')
            } else {
              this.$refs.formTableRef.initFundTable()
            }
          } else {
          }
        }
      } else {
        this.$refs.formTableRef.getDetailList()
        // setTimeout(() => {
        //   this.$router.go(0)
        // }, 1000)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.funds-add-page {
  .filterContainer {
    margin-bottom: 15px;
  }
  .el-table {
    .poc-table-header-row {
      height: 10px !important;
    }
  }

  .flexRow {
    display: flex;
    flex-direction: row;
  }
  .flexCenter {
    justify-content: center;
    align-items: center;
  }
  .AlCenter {
    align-items: center;
  }
  .flexBetw {
    display: flex;
    justify-content: space-between;
    line-height: 5px;
  }

  .buttonUnactive {
    width: 68px;
    height: 30px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ba9765;
    border-radius: 4px;
    color: #ba9765;
    font-size: 14px;
    line-height: 15px;
  }
  .buttonActiveBig {
    width: 104px;
    height: 30px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 15px;
  }
  .exportButton {
    width: 66px;
    margin-left: 16px;
    margin-right: 16px;
    text-align: center;
    line-height: 30px;
  }
  .buttonrefresh {
    width: 30px;
    height: 30px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ba9765;
    border-radius: 4px;
    color: #ba9765;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    .buttonrefreshSpan {
      display: flex;
      justify-content: center !important;
    }
  }
  .buttonBigActive {
    margin-left: 16px;
    width: 68px;
    height: 30px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 15px;
  }
  .buttonBigunActive {
    width: 96px;
    height: 30px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ba9765;
    border-radius: 4px;
    color: #ba9765;
    font-size: 14px;
    line-height: 15px;
  }
  .buttonActive {
    width: 68px;
    height: 30px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 15px;
  }

  .upContainer {
    width: 90px;
    margin-top: 7px;
    justify-content: flex-end;
  }
  .up {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background: rgba(186, 151, 101, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    .strangle {
      width: 0;
      height: 0;
      border: 6px solid;
      border-color: transparent transparent #ba9765 transparent;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: -3px;
      &.down {
        border-color: #ba9765 transparent transparent transparent;
        top: 3px;
      }
    }
  }
  .upWord {
    color: #ba9765;
    font-size: 14px;
    margin-left: 1px;
    cursor: pointer;
    margin-left: 8px;
  }
  .opeartionBox {
    padding: 23px 0 0px 0;
    box-sizing: border-box;
    // margin: 0 auto;
  }
  .scopeItem {
    width: 125px;
    margin-right: 72px;
    color: #828282;
    font-size: 14px;
    font-weight: 400;
    // border: 1px solid red;
    text-align: center;
  }
  .active {
    color: #303133;
    text-align: center;
  }
  .scopeContainer {
    margin-top: 23px;
  }
  .guidaoactive {
    padding-top: 14px;
    box-sizing: border-box;
    width: 125px;
    overflow: hidden;
  }
  .guidao {
    font-size: 0;
    width: 100%;
    padding: 0px 0 12px 0;
    box-sizing: border-box;
    overflow: hidden;
    .guidaoImg {
      overflow: hidden;
    }
  }
  .require-color {
    line-height: 18px;
    color: $--color-require-text;
  }
  .lableDetail {
    color: #4e4e50;
    font-size: 14px;
    font-weight: 400;
    font-family: PingFangSC-Regular, PingFang SC;
  }
  .lableContent {
    color: #545454;
    font-size: 14px;
    // font-weight: 300;
    margin-left: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
  }
  .lableContent span {
    color: #545454;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
  }
}
.topTabFlex {
  position: relative;
  div {
    &:first-child {
      width: 100%;
    }
  }
  .rightButton {
    position: absolute;
    top: 15px;
    right: 20px;
  }
}
</style>
<style lang="scss">
.funds-add-page {
  .el-date-editor.el-input {
    width: 100% !important;
  }
}
.shebao {
  font-size: 16px;
  margin-bottom: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #303133;
}
</style>
