<template>
  <div class="fundFormTable">
    <PocTable
      :data="tableData"
      size="large"
      v-loading="loading"
      highlight-current-row
      :current-page.sync="pageConfig.pageNum"
      :total="pageConfig.total"
      @size-change="pageSizeChange"
      @page-current-change="pageChange"
      :max-height="maxHeight"
    >
      <el-table-column
        v-for="item in columnConfig"
        :key="item.key"
        :label="item.name"
        :if="item.visible"
        :fixed="item.fixed"
        :sortable="item.sortable"
        align="center"
        :show-overflow-tooltip="item.isTooltip"
        :width="item.width"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.rowform && addFlag.addFlag !== 'goDetail'">
            <el-form :model="scope.row" :rules="rules">
              <el-form-item :prop="item.key" style="margin-bottom: 0">
                <el-input
                  v-if="item.key !== 'city'"
                  v-model="scope.row[item.key]"
                ></el-input>
                <el-select
                  v-if="item.key === 'city'"
                  v-model="scope.row[item.key]"
                  clearable
                  placeholder=" "
                >
                  <el-option
                    v-for="item in cityList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </span>
          <span v-else>{{ scope.row[item.key] }}</span>
        </template>
        <el-table-column
          v-for="child in item.children"
          :key="child.key"
          :label="child.name"
          :prop="item.key"
          :fixed="item.fixed"
          :sortable="item.sortable"
          align="center"
          :show-overflow-tooltip="item.isTooltip"
          :width="child.width"
        >
          <template slot-scope="scope">
            <span v-if="child.key === 'key'">
              <span class="require-color poc-size12">*</span>
            </span>
            <span v-else-if="child.key === 'index'">
              {{ formatZeroIndex(scope.$index + 1) }}
            </span>
            <span
              v-else-if="scope.row.rowform && addFlag.addFlag !== 'goDetail'"
            >
              <el-form :model="scope.row" :rules="rules">
                <el-form-item :prop="child.key" style="margin-bottom: 0">
                  <el-input
                    type="number"
                    v-model="scope.row[child.key]"
                    :class="child.isRate ? 'percent' : ''"
                  ></el-input>
                </el-form-item>
              </el-form>
            </span>
            <span v-else>{{ scope.row[child.key] }}</span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        v-if="addFlag.addFlag !== 'goDetail'"
        :label="$t('操作')"
        width="130"
        fixed="right"
        align="center"
      >
        <template slot-scope="scope">
          <svg-icon
            icon="delete"
            role="socialdelete"
            class="tablePointSvgClass"
            v-permission
            title="删除"
            @click.native="deleteRow(scope.$index)"
          ></svg-icon>
          <svg-icon
            icon="tableCopy"
            role="socialcopy"
            class="tablePointSvgClass"
            v-permission
            title="复制"
            @click.native="copyRow(scope.$index)"
          ></svg-icon>
          <svg-icon
            icon="addUp"
            class="tablePointSvgClass"
            role="assignorgs"
            v-permission
            title="分配机构"
            @click.native="allocatingAgency(scope.row, scope.$index)"
          ></svg-icon>
        </template>
      </el-table-column>
    </PocTable>
    <template v-if="deleteConfirmDialogVisible">
      <DeleteConfirmDialog
        :deleteConfirmDialogVisible.sync="deleteConfirmDialogVisible"
        :deleteId="deleteId"
        @delete-confirm="handleDeleteConfirm"
      />
    </template>
    <OrganizationsDialog ref="OrganizationsDialogRef" @onConfirm="onConfirm" />
  </div>
</template>
<script>
import { check, formatZero, typeRange } from '@/utils/util'
import { mapGetters } from 'vuex'
import DeleteConfirmDialog from '@/components/DeleteConfirmDialog'
import OrganizationsDialog from '@/views/fundsManage/dialog/OrganizationsDialog'
export default {
  mixins: [$PCommon.TableMixin],
  name: 'fundsFormTable',
  components: {
    DeleteConfirmDialog,
    OrganizationsDialog
  },
  props: {
    fundsAddForm: {
      type: Object
    },
    importData: {
      type: Array
    },
    addFlag: {
      type: Object
    }
  },
  data() {
    return {
      dataTypeList: [], //媒体类型列表
      tableData: [],
      deleteConfirmDialogVisible: false, //删除行弹框
      deleteId: '', //删除行参数
      columnConfig: [],
      rules: {},
      maxHeight: 500,
      cityList: [],
      loading: true,
      allocIndex: ''
    }
  },
  watch: {
    importData(newValue, oldValue) {
      if (newValue.length) {
        newValue.map((v) => {
          v.rowform = {}
        })
        this.tableData = newValue
      }
    }
  },
  created() {
    this.initFundTable()
  },
  computed: {
    ...mapGetters(['userInfo']),
    tableDataInterface() {
      let obj = {}
      Object.keys(this.rules).map((v) => {
        obj[v] = ''
      })
      return obj
    }
  },
  methods: {
    initFundTable() {
      this.initTableRules()
      if (
        this.addFlag.addFlag === 'copy' ||
        this.addFlag.addFlag === 'goDetail' ||
        this.addFlag.addFlag === 'edit'
      ) {
        this.getDetailList()
      } else {
        this.tableData.splice(0, 0, {
          ...this.tableDataInterface,
          key: Date.now(),
          rowform: {}
        })
      }
      this.$Api.funds.getWorkCity().then((res) => {
        if (res && res.code === 200) {
          this.cityList = res.data
          this.loading = false
        }
      })
    },
    initTableRules() {
      const columnConfig = [
        {
          key: 'city',
          name: '城市',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: 'left', // 固定列的位置(left, right)
          width: 150, // 默认宽度，像素
          isTooltip: true,
          isInput: true
        },

        {
          key: 'remark',
          name: this.$t('备注'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: 'left',
          width: 200,
          isInput: true,
          isTooltip: false
        },
        {
          name: '养老保险',
          children: [
            {
              key: 'endowmentMin',
              name: '保底基数',
              dataType: 'string',
              visible: true,
              sortable: false,
              fixed: 'left',
              width: 180,
              isInput: true,
              isTooltip: true
            },
            {
              key: 'endowmentMax',
              name: '封顶基数',
              dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
              visible: true, // 是否展示在表格列中
              sortable: false, // 是否可以排序
              fixed: 'left', // 固定列的位置(left, right)
              width: 180, // 默认宽度，像素
              // isSelect: true,
              isInput: true,
              isTooltip: true
            },
            {
              key: 'endowmentRate',
              name: '公司占比',
              dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
              visible: true, // 是否展示在表格列中
              sortable: false, // 是否可以排序
              fixed: 'left', // 固定列的位置(left, right)
              width: 180, // 默认宽度，像素
              isSelect: true,
              isTooltip: false,
              required: true,
              isRate: true
            }
          ]
        },
        {
          name: '医疗保险',
          children: [
            {
              key: 'medicalMin',
              name: '保底基数',
              dataType: 'string',
              visible: true,
              sortable: false,
              fixed: 'left',
              width: 180,
              isInput: true,
              isTooltip: true
            },
            {
              key: 'medicalMax',
              name: '封顶基数',
              dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
              visible: true, // 是否展示在表格列中
              sortable: false, // 是否可以排序
              fixed: 'left', // 固定列的位置(left, right)
              width: 180, // 默认宽度，像素
              // isSelect: true,
              isInput: true,
              isTooltip: false,
              required: true
            },
            {
              key: 'medicalRate',
              name: '公司占比',
              dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
              visible: true, // 是否展示在表格列中
              sortable: false, // 是否可以排序
              fixed: 'left', // 固定列的位置(left, right)
              width: 180, // 默认宽度，像素
              isSelect: true,
              isTooltip: false,
              required: true,
              isRate: true
            },
            {
              key: 'medicalGrant',
              name: '医疗补助金-大病医疗',
              dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
              visible: true, // 是否展示在表格列中
              sortable: false, // 是否可以排序
              fixed: 'left', // 固定列的位置(left, right)
              width: 180, // 默认宽度，像素
              isSelect: true,
              isTooltip: false,
              required: true
            }
          ]
        },
        {
          name: '失业保险',
          children: [
            {
              key: 'unemploymentMin',
              name: '保底基数',
              dataType: 'string',
              visible: true,
              sortable: false,
              fixed: 'left',
              width: 180,
              isInput: true,
              isTooltip: true
            },
            {
              key: 'unemploymentMax',
              name: '封顶基数',
              dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
              visible: true, // 是否展示在表格列中
              sortable: false, // 是否可以排序
              fixed: 'left', // 固定列的位置(left, right)
              width: 180, // 默认宽度，像素
              // isSelect: true,
              isInput: true,
              isTooltip: false,
              required: true
            },
            {
              key: 'unemploymentRate',
              name: '公司占比',
              dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
              visible: true, // 是否展示在表格列中
              sortable: false, // 是否可以排序
              fixed: 'left', // 固定列的位置(left, right)
              width: 180, // 默认宽度，像素
              isSelect: true,
              isTooltip: false,
              required: true,
              isRate: true
            }
          ]
        },
        {
          name: '工伤保险',
          children: [
            {
              key: 'employmentInjuryMin',
              name: '保底基数',
              dataType: 'string',
              visible: true,
              sortable: false,
              fixed: 'left',
              width: 180,
              isInput: true,
              isTooltip: true
            },
            {
              key: 'employmentInjuryMax',
              name: '封顶基数',
              dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
              visible: true, // 是否展示在表格列中
              sortable: false, // 是否可以排序
              fixed: 'left', // 固定列的位置(left, right)
              width: 180, // 默认宽度，像素
              // isSelect: true,
              isInput: true,
              isTooltip: false,
              required: true
            },
            {
              key: 'employmentInjuryRate',
              name: '公司占比',
              dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
              visible: true, // 是否展示在表格列中
              sortable: false, // 是否可以排序
              fixed: 'left', // 固定列的位置(left, right)
              width: 180, // 默认宽度，像素
              isSelect: true,
              isTooltip: false,
              required: true,
              isRate: true
            }
          ]
        },
        {
          name: '生育保险',
          children: [
            {
              key: 'maternityMin',
              name: '保底基数',
              dataType: 'string',
              visible: true,
              sortable: false,
              fixed: 'left',
              width: 180,
              isInput: true,
              isTooltip: true
            },
            {
              key: 'maternityMax',
              name: '封顶基数',
              dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
              visible: true, // 是否展示在表格列中
              sortable: false, // 是否可以排序
              fixed: 'left', // 固定列的位置(left, right)
              width: 180, // 默认宽度，像素
              // isSelect: true,
              isInput: true,
              isTooltip: false,
              required: true
            },
            {
              key: 'maternityRate',
              name: '公司占比',
              dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
              visible: true, // 是否展示在表格列中
              sortable: false, // 是否可以排序
              fixed: 'left', // 固定列的位置(left, right)
              width: 180, // 默认宽度，像素
              isSelect: true,
              isTooltip: false,
              required: true,
              isRate: true
            }
          ]
        },
        {
          name: '住房公积金',
          children: [
            {
              key: 'providentFundMin',
              name: '保底基数',
              dataType: 'string',
              visible: true,
              sortable: false,
              fixed: 'left',
              width: 180,
              isInput: true,
              isTooltip: true
            },
            {
              key: 'providentFundMax',
              name: '封顶基数',
              dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
              visible: true, // 是否展示在表格列中
              sortable: false, // 是否可以排序
              fixed: 'left', // 固定列的位置(left, right)
              width: 180, // 默认宽度，像素
              // isSelect: true,
              isInput: true,
              isTooltip: false,
              required: true
            },
            {
              key: 'providentFundRate',
              name: '公司占比',
              dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
              visible: true, // 是否展示在表格列中
              sortable: false, // 是否可以排序
              fixed: 'left', // 固定列的位置(left, right)
              width: 180, // 默认宽度，像素
              isSelect: true,
              isTooltip: false,
              required: true,
              isRate: true
            }
          ]
        }
      ]
      this.columnConfig = columnConfig
      let rules = {}
      const rule = [{ required: true, message: '', trigger: ['blur'] }]
      columnConfig.map((v) => {
        if (v.children) {
          v.children.map((c) => {
            if (c.key !== 'medicalGrant') {
              rules[c.key] = rule
            }
          })
        } else {
          if (v.key !== 'remark') {
            rules[v.key] = rule
          }
        }
      })
      this.rules = rules
    },
    getData() {
      return { tableData: this.tableData, columnConfig: this.columnConfig }
    },
    checkAmount(e) {
      e.target.value =
        e.target.value.match(/^[0-9,]*(\.?[0-9]{0,2})/)[0] || null
      this.text = e.target.value
    },
    async getDetailList() {
      // 获取公积金明细列表
      const { data, code } = await this.$Api.funds.getDetailList({
        topicId: this.addFlag.fundsId,
        ...this.pageConfig
      })
      this.queryLoading = false
      if (code === 200) {
        //copy进来，接口获取的值要可编辑，input框显现
        if (data.list) {
          data.list.map((v) => {
            v.rowform = {}
          })
          this.tableData = data.list

          this.pageConfig.total = data.total
        } else {
          this.tableData.splice(0, 0, {
            ...this.tableDataInterface,
            key: Date.now(),
            rowform: {}
          })
        }
      }
    },

    // 确认删除
    async handleDeleteConfirm(index) {
      this.tableData.splice(index, 1)
      this.$message({
        type: 'success',
        message: '删除成功!'
      })
      if (this.tableData.length < 1) {
        //复制或者导入时，第一条有数据。删除后给一个初始状态
        this.tableData.splice(index + 1, 0, {
          ...this.tableDataInterface,
          key: Date.now(),
          rowform: {}
        })
      }
    },

    async getDataType() {
      const { data, code } = await this.$Api.doucmentMatain.getformat({
        type: 'field_type'
      })

      if (code == 200 && this.$RESPONSE_STATUS.SUCCESS === 0) {
        console.log('数据类型---', data)
        this.dataTypeList = data
      }
    },
    // 行号补0
    formatZeroIndex(value) {
      return formatZero(value, 3)
    },
    showAdd(index) {
      return this.tableData.length < 2 || index + 1 === this.tableData.length
    },
    allocatingAgency(row, index) {
      let id = row.id
      this.allocIndex = index
      this.$refs.OrganizationsDialogRef.show({ id: id })
    },
    addRow(index) {
      this.tableData.push({
        ...this.tableDataInterface,
        key: Date.now(),
        rowform: {}
      })
    },
    copyRow(index) {
      let mod = JSON.parse(JSON.stringify(this.tableDataInterface))
      Object.keys(mod).map((m) => {
        mod[m] = this.tableData[index][m]
          ? this.tableData[index][m].toString()
          : ''
      })
      this.tableData.push({
        ...mod,
        id: '',
        topicId: this.tableData[0].topicId,
        key: Date.now(),
        rowform: {}
      })
      // this.tableData.splice(index + 1, 0, {
      //   ...mod,
      //   topicId: this.tableData[0].topicId,
      //   key: Date.now(),
      //   rowform: {}
      // })
    },
    deleteRow(index) {
      if (this.addFlag.addFlag == 'add') {
        //新增的时候第一条不可删除
        //   if (this.tableData.length < 2) {
        //   this.$message({
        //     type: 'info',
        //     message: '初始数据，不能删除!'
        //   })
        //   return false
        // }
      } else {
        //复制或者导入时，第一条有数据。删除后给一个初始状态
        //复制或者导入时，删除的只剩一个初始数据，初始数据不可删,删了会增加
        //   if (this.tableData.length < 2 && ) {
        //   this.$message({
        //     type: 'info',
        //     message: '初始数据，不能删除!'
        //   })
        //   return false
        // }
      }

      this.deleteId = index
      this.deleteConfirmDialogVisible = true
    },
    onConfirm(val) {
      this.tableData[this.allocIndex].organizationsList = val.split(',')
      //this.$emit('orgSave')
    },
    checkData() {
      for (let i = 0; i < this.tableData.length; i++) {
        let v = this.tableData[i]
        let temV = JSON.parse(JSON.stringify(v))
        console.log(temV)
        delete temV.medicalGrant
        delete temV.id
        delete temV.invalidDate
        delete temV.effectiveDate
        delete temV.status
        delete temV.organizationsList
        console.log('temV', Object.values(temV))
        if (temV.rowform && Object.values(temV).some((e) => e === '')) {
          //是新增行、复制的行
          return false
        }
      }
      return true
    },
    resizeHandle() {
      this.maxHeight =
        document.getElementsByTagName('body')[0].clientHeight - 430 + 'px'
    }
  },
  mounted() {
    this.resizeHandle()
    window.addEventListener('resize', this.resizeHandle)
  }
}
</script>
<style lang="scss" scoped>
.budget-form-table-page {
  margin-bottom: $MP32;
  .el-table .cell {
    padding: 0;
    line-height: $MP24;
  }
  .require-color {
    color: $--color-require-text;
  }
  .err {
    ::v-deep .el-input__inner {
      border-color: $--color-error;
    }
  }
  .require-item::before {
    content: '*';
    color: $--color-require-text;
    margin-right: $MP4;
  }

  ::v-deep .el-table__body-wrapper {
    padding-bottom: $MP16;
  }
  ::v-deep .el-table .cell {
    padding: 0 $MP2;
  }
}
</style>
<style lang="scss">
.fundFormTable {
  .el-table__header-wrapper {
    height: 78px !important;
  }
  .percent {
    position: relative;
    &::after {
      content: '%';
      position: absolute;
      right: 4px;
      color: #333;
    }
  }
  .el-table .is-group .poc-table-header-row > th,
  .el-table .is-group .poc-table-header-row > th.el-table__cell {
    border-right: 2px solid #fff;
  }
  // .el-table .is-group .poc-table-header-row > th, .el-table .is-group .poc-table-header-row > th.el-table__cell{
  //   position: relative;
  //   &::after {
  //     content:url(../../assets/img/edit.png);
  //     position: absolute;
  //     right: 4px;
  //     color: #fff;
  //   }
  // }
}
</style>
