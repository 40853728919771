import request from '@/utils/request'
// 权限角色弹出层获取待已配列表 ---preRole
export const sysRoleGetAssignList = (roleId) => {
  return request({
    url: '/sysRole/getAssignList',
    params: {
      roleId
    }
  })
}
// 权限角色弹出层获取待分配列表  ---preRole
export const sysRoleGetUnAssignList = (roleId) => {
  return request({
    url: '/sysRole/getUnAssignList',
    params: {
      roleId
    }
  })
}

// 获取组织机构已分配列表   ----  org
export const sysUserGetOrgAssignList = (userId) => {
  return request({
    url: '/sysUser/getOrgAssignList',
    params: {
      userId
    }
  })
}
// 获取组织机构待分配列表   ----  org
export const sysUserGetOrgUnAssignList = (userId) => {
  return request({
    url: '/sysUser/getOrgUnAssignList',
    params: {
      userId
    }
  })
}
// 获取角色已分配列表 --- role
export const sysUserGetRoleAssignList = (userId) => {
  return request({
    url: '/sysUser/getRoleAssignList',
    params: {
      userId
    }
  })
}
// 获取角色待分配列表 --- role
export const sysUserGetRoleUnAssignList = (userId) => {
  return request({
    url: '/sysUser/getRoleUnAssignList',
    params: {
      userId
    }
  })
}
// 获取权限待分配列表  --- pre
export const sysPermissionsGetUnAssignList = (permissionsId) => {
  return request({
    url: 'sysPermissions/getUnAssignList',
    params: {
      permissionsId
    }
  })
}
// 获取权限分配列表  --- pre
export const sysPermissionsGetAssignList = (permissionsId) => {
  return request({
    url: 'sysPermissions/getAssignList',
    params: {
      permissionsId
    }
  })
}
