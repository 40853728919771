<template>
  <el-dialog
    title="分配机构"
    :visible.sync="dialogVisible"
    width="60%"
    :before-close="close"
    append-to-body
    destroy-on-close
    custom-class="OrganizationsDialog"
  >
    <div class="OrganizationsDialog__title">
      <el-form
        :inline="true"
        :model="formInline"
        label-position="top"
        class="demo-form-inline"
      >
        <el-form-item label="Work City">
          <el-select
            v-model="formInline.workCity"
            clearable
            placeholder="Work City"
          >
            <el-option
              v-for="item in workCityList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="HFM Code">
          <el-input
            v-model="formInline.hfmCode"
            placeholder="HFM Code"
          ></el-input>
        </el-form-item>
        <el-form-item label="Assigment/Project (Level2)">
          <el-select
            clearable
            v-model="formInline.assigmentProjectLevel2"
            filterable
            placeholder="请选择"
            class="industry-selector"
          >
            <el-option
              v-for="item in assigmentProjectLevel2List"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Legal Entity(中文)">
          <el-input
            v-model="formInline.LegalEntity"
            placeholder="Legal Entity(中文)"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <PocTable
      ref="dataTable"
      v-loading="loading"
      :data="tableData"
      :current-page.sync="pageConfig.pageNum"
      :total="pageConfig.total"
      hidepage
      @selection-change="handleSelectionChange"
      @size-change="pageSizeChange"
      @page-current-change="pageChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        v-for="item in columnConfig"
        v-if="item.visible"
        :key="item.key"
        :prop="item.key"
        :label="item.name"
        :fixed="item.fixed"
        :sortable="item.sortable"
        :width="item.width"
      ></el-table-column>
    </PocTable>
    <div class="bottomButton" v-if="status !== 'chat'">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getWorkCity } from '@/api/organization'
import {
  getAssigmentProjectLevel2,
  getOrganizations
} from '@/api/personnelRequisition'

export default {
  name: 'OrganizationsDialog',
  mixins: [$PCommon.TableMixin],
  data() {
    return {
      dialogVisible: false,
      formInline: {
        workCity: '',
        hfmCode: '',
        id: '',
        assigmentProjectLevel2: '',
        LegalEntity: '',
        type: ''
      },
      loading: false,
      workCityList: [],
      tableData: [],
      columnConfig: [
        {
          key: 'workCity',
          name: 'Work City',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: null // 默认宽度，像素
        },
        {
          key: 'hfmCode',
          name: 'HFM Code',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'assignmentProjectLevel2',
          name: 'Assignment/Project (Level2)',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: null // 默认宽度，像素
        },
        {
          key: 'legalEntity',
          name: 'Legal Entity(中文)',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: null // 默认宽度，像素
        }
      ],
      assigmentProjectLevel2List: [],
      selectVal: [],
      status: ''
    }
  },
  methods: {
    show(val) {
      this.formInline.id = val.id
      this.status = val.status || ''
      if (val.status === 'chat') {
        this.formInline.type = 'view'
      }
      getWorkCity().then((res) => {
        if (res && res.code === 200) {
          this.workCityList = res.data
        }
      })
      getAssigmentProjectLevel2().then((res) => {
        if (res && res.code === 200) {
          this.assigmentProjectLevel2List = res.data
        }
      })
      this.queryApi()
      this.dialogVisible = true
    },
    queryApi() {
      getOrganizations({ ...this.formInline }).then((res) => {
        if (res && res.code === 200) {
          this.tableData = res.data
          this.$nextTick(() => {
            let selectData = this.tableData.filter((item) => item.checked)
            selectData.forEach((row) => {
              this.$refs.dataTable.toggleRowSelection(row)
            })
          })
        }
      })
    },
    close() {
      Object.keys(this.formInline).forEach((item) => {
        this.formInline[item] = ''
      })
      this.dialogVisible = false
    },
    submit() {
      this.$emit('onConfirm', this.selectVal.map((item) => item.id).toString())
      this.close()
    },
    onSearch() {
      this.queryApi()
    },
    handleSelectionChange(val) {
      this.selectVal = val
    }
  }
}
</script>

<style lang="scss" scoped>
.OrganizationsDialog {
  padding-bottom: 10px;
  .bottomButton {
    padding: 20px;
    text-align: right;
  }
}
</style>
