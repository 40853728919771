<template>
  <el-dialog class="delete-confirm-dialog" :title="$t('提示')" width="420px" top="200px" :append-to-body="appendToBody" :close-on-click-modal="closeDialogModal" :visible.sync="show">
    <div align="left">{{ $t(`${deleteContent}`) }}</div>
    <div slot="footer" align="right" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="onConfirm">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    name: 'DeleteConfirmDialog',
    props: {
      deleteConfirmDialogVisible:{
        type: Boolean,
        default: false
      },
      appendToBody: {
        type:Boolean,
        default:false
      },
      deleteId: {
        type:[String,Number],
        default:null
      },
      deleteContent: {
        type: String,
        default: '此操作将删除该信息，是否继续?'
      }
    },
    data() {
      return {
        show: this.deleteConfirmDialogVisible,
        closeDialogModal: false,
      };
    },
    watch: {
      show(v) {
        this.$emit('update:deleteConfirmDialogVisible', v);
      },
      deleteConfirmDialogVisible(v) {
        this.show = v;
      },
    },
    methods: {
      onConfirm(ruleForm) {
        console.log(this.deleteId)
        this.$emit('delete-confirm' , this.deleteId);
        this.show = false;
      },
    }
  };
</script>
<style scoped lang="scss">
.delete-confirm-dialog{
  ::v-deep .el-dialog__body{
    // padding: 16px;
    padding-left: 32px!important;
    box-sizing: border-box;
  }
  ::v-deep .el-dialog__title{
    color: #303133;
  }
  .el-message-box__wrapper .el-message-box__content {
    padding: 0 32px;
}
}
</style>